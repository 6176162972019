import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import './NavPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';

const NavPage = () => {
    const [settings, setSettings] = useState({ csvote_enabled: true, songrequests_enabled: true });
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [loginForm, setLoginForm] = useState({ username: '', password: '' });

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/settings`);
                const data = await response.json();
                const settingsObject = data.reduce((acc, setting) => {
                    acc[setting.key] = setting.value;
                    return acc;
                }, {});
                setSettings(settingsObject);
            } catch (error) {
                console.error('Error fetching settings:', error);
            }
        };

        // Fetch settings on initial render
        fetchSettings();

        // Poll for settings every 5 seconds
        const intervalId = setInterval(fetchSettings, 5000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);

    }, []);

    useEffect(() => {
        // Check if the user is already logged in by checking localStorage
        const storedToken = localStorage.getItem('token');
        const storedUsername = localStorage.getItem('username');

        if (storedToken && storedUsername) {
            setIsLoggedIn(true);
            setUsername(storedUsername);
        }
    }, []);

    const handleLogin = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(loginForm)
            });

            const data = await response.json();

            if (data.token) {
                // Store the token and username in local storage
                localStorage.setItem('token', data.token);
                localStorage.setItem('username', loginForm.username);

                // Update state
                setIsLoggedIn(true);
                setUsername(loginForm.username);
                toast.success('Logged in successfully');
            } else {
                toast.error(data.error || 'Login failed');
            }
        } catch (error) {
            toast.error('Failed to log in');
            console.error('Login error:', error);
        }
    };

    const handleLogout = () => {
        // Clear the token and username from local storage and update state
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        setIsLoggedIn(false);
        setUsername('');
        toast.success('Logged out successfully');
    };

    const handleCsvoteClick = (e) => {
        if (!settings.csvote_enabled) {
            e.preventDefault();
            toast.error('Voting is closed');
        }
    };

    const handleSongRequestsClick = (e) => {
        if (!settings.songrequests_enabled) {
            e.preventDefault();
            toast.error('Not taking song requests at this time');
        }
    };

    return (
        <div className="navpage-container">
            <h1>Thanks for watching laoloser's live stream!</h1>
            <p>Select an option below to get started:</p>

            <div className="links-container">
                <Link to="/csvote" className="navpage-link" onClick={handleCsvoteClick}>
                    Vote for the Challenge Show
                </Link>
                <Link to="/songrequests" className="navpage-link" onClick={handleSongRequestsClick}>
                    Song Requests
                </Link>
                
                {/* Gacha Button - only show if logged in */}
                {isLoggedIn && (
                    <Link to="/gacha" className="navpage-link">
                        Gacha
                    </Link>
                )}
            </div>

            <br />
            <br />

            {/* User Account Section */}
            <div className="user-account-section">
                {isLoggedIn ? (
                    <div>
                        <p>Logged in as <strong>{username}</strong></p>
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                ) : (
                    <div>
                        <input
                            type="text"
                            placeholder="Username"
                            value={loginForm.username}
                            onChange={(e) => setLoginForm({ ...loginForm, username: e.target.value })}
                        />
                        <br />
                        <input
                            type="password"
                            placeholder="Password"
                            value={loginForm.password}
                            onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })}
                        />
                        <br />
                        <button onClick={handleLogin}>Login</button>
                        <Link to="/register">
                            <button>Register</button>
                        </Link>
                    </div>
                )}
            </div>

            <br />
            <br />

            <h2>Links</h2>
            <a href="https://discord.gg/RQs7UGJQrN"><h3>Join our Discord!</h3></a>
            <a href="https://LuckyPrime.us"><h3>Check out my online store</h3></a>

            <div className="social-media-icons">
                <a href="https://www.instagram.com/laoloser" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                <a href="https://www.youtube.com/@laoloserr" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faYoutube} size="2x" />
                </a>
                <a href="https://www.tiktok.com/@laoloser" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTiktok} size="2x" />
                </a>
            </div>

            <footer className="footer">
                <Link to="/tos">Terms of Service & Privacy Policy</Link>
            </footer>

            <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
        </div>
    );
};

export default NavPage;
