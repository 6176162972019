import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from 'react-switch';  // Import the switch component
import './RequestAdmin.css';

const RequestAdmin = () => {
    const [queue, setQueue] = useState([]);
    const [csvoteEnabled, setCsvoteEnabled] = useState(true);
    const [songrequestsEnabled, setSongrequestsEnabled] = useState(true);

    useEffect(() => {
        // Fetch the current queue
        const fetchQueue = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sradmin`);
                setQueue(response.data);
            } catch (error) {
                console.error('Error fetching queue:', error);
            }
        };

        // Fetch the current settings
        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/settings`);
                const data = response.data.reduce((acc, setting) => {
                    acc[setting.key] = setting.value;
                    return acc;
                }, {});
                setCsvoteEnabled(data.csvote_enabled);
                setSongrequestsEnabled(data.songrequests_enabled);
            } catch (error) {
                console.error('Error fetching settings:', error);
            }
        };

        // Initial fetch
        fetchQueue();
        fetchSettings();

        // Set up interval to refresh the queue every 5 seconds
        const intervalId = setInterval(fetchQueue, 5000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/sradmin/${id}`);
            setQueue(queue.filter(request => request.id !== id)); // Update the queue after deletion
        } catch (error) {
            console.error('Error deleting request:', error);
        }
    };

    const handleDeleteAll = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/sradmin`);
            setQueue([]); // Clear the queue after deleting all requests
        } catch (error) {
            console.error('Error deleting all requests:', error);
        }
    };

    const handleResetVotes = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reset-csvote`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Custom-Auth': 'muku' // Ensure this key is secure
                }
            });

            if (response.ok) {
                toast.success('All votes have been reset successfully.');
            } else {
                throw new Error('Failed to reset votes');
            }
        } catch (error) {
            console.error('Error resetting votes:', error);
            toast.error('Error resetting votes');
        }
    };

    const toggleCsvote = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings`, { key: 'csvote_enabled', value: !csvoteEnabled });
            setCsvoteEnabled(!csvoteEnabled);
            toast.success(`Challenge Show Voting is now ${!csvoteEnabled ? 'enabled' : 'disabled'}.`);
        } catch (error) {
            console.error('Error toggling csvote:', error);
        }
    };
    
    const toggleSongRequests = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings`, { key: 'songrequests_enabled', value: !songrequestsEnabled });
            setSongrequestsEnabled(!songrequestsEnabled);
            toast.success(`Song Requests are now ${!songrequestsEnabled ? 'enabled' : 'disabled'}.`);
        } catch (error) {
            console.error('Error toggling songrequests:', error);
        }
    };

    return (
        <div className="request-admin">
            <h1>Admin Tools</h1>
            <button className="delete-all-button" onClick={handleDeleteAll}>Delete ALL SONG Requests</button>
            <button className="reset-votes-button" onClick={handleResetVotes}>Delete CHALLENGE SHOW Votes</button>
            <ul className="queue-list">
                {queue.map((request) => (
                    <li key={request.id} className="queue-item">
                        <span>{request.title} - Requested by {request.user_name}</span>
                        {request.special_instructions && (
                            <div style={{ color: 'aqua' }}>
                                Special Instructions: {request.special_instructions}
                            </div>
                        )}
                        <button className="delete-button" onClick={() => handleDelete(request.id)}>Delete</button>
                    </li>
                ))}
            </ul>

            <h2>Manage Features</h2>
            <div className="toggle-container">
                <label>
                    Challenge Show Voting
                    <Switch
                        onChange={toggleCsvote}
                        checked={csvoteEnabled}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                        id="csvote-switch"
                    />
                </label>
                <br></br>
                <label>
                    Song Requests
                    <Switch
                        onChange={toggleSongRequests}
                        checked={songrequestsEnabled}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                        id="songrequests-switch"
                    />
                </label>
            </div>

            <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
        </div>
    );
};

export default RequestAdmin;
