import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './GachaAdminTools.css'; // Add CSS for styling

const GachaAdminTools = () => {
    const [code, setCode] = useState('');
    const [pulls, setPulls] = useState(0);
    const [redemptionCodes, setRedemptionCodes] = useState([]);

    useEffect(() => {
        fetchRedemptionCodes();
    }, []);

    // Fetch all redemption codes
    const fetchRedemptionCodes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/gacha/admin/codes`);
            setRedemptionCodes(response.data);
        } catch (error) {
            console.error('Error fetching redemption codes:', error);
            toast.error('Failed to fetch redemption codes');
        }
    };

    // Handle creating a new code
    const handleCreateCode = async () => {
        if (!code || pulls <= 0) {
            toast.error('Invalid code or pulls');
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/gacha/admin/create-code`, { code, pulls });
            toast.success('Code created successfully');
            setCode('');
            setPulls(0);
            fetchRedemptionCodes(); // Refresh the list
        } catch (error) {
            console.error('Error creating code:', error);
            toast.error('Failed to create code');
        }
    };

    // Handle deleting a code
    const handleDeleteCode = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/gacha/admin/codes/${id}`);
            toast.success('Code deleted successfully');
            fetchRedemptionCodes(); // Refresh the list
        } catch (error) {
            console.error('Error deleting code:', error);
            toast.error('Failed to delete code');
        }
    };

    return (
        <div className="admin-tools-container">
            <h1>Gacha Admin Tools</h1>

            <h2>Create a New Redemption Code</h2>
            <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter code"
            />
            <input
                type="number"
                value={pulls}
                onChange={(e) => setPulls(Number(e.target.value))}
                placeholder="Enter pulls"
            />
            <button onClick={handleCreateCode}>Create Code</button>

            <h2>Existing Redemption Codes</h2>
            <ul>
            {redemptionCodes.map((redemption) => {
  console.log(redemption);  // Check if id is present in the redemption object
  return (
    <li key={redemption.code}>
      {redemption.code} - {redemption.pulls} pulls
      {redemption.used_by ? ` ( Used by: ${redemption.used_by} ) ` : ' (Unused)'}
      <button onClick={() => handleDeleteCode(redemption.id)}>Delete</button>
    </li>
  );
})}

            </ul>

            <ToastContainer position="top-center" autoClose={2000} theme="dark" hideProgressBar={false} />
        </div>
    );
};

export default GachaAdminTools;
