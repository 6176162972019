import React from 'react';

const ToS = () => {
    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto', lineHeight: '1.6', fontFamily: 'Arial, sans-serif' }}>
            <h1>Terms of Service</h1>
            <p>Last updated: 8.17.2024</p>

            <h2>1. Acceptance of Terms</h2>
            <p>By accessing and using laoloser.com, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, please do not use the website.</p>

            <h2>2. Changes to Terms</h2>
            <p>We reserve the right to modify these Terms of Service at any time without prior notice. Your continued use of the website after any changes constitutes your acceptance of the new terms.</p>

            <h2>3. Use of Website</h2>
            <p>You agree to use laoloser.com only for lawful purposes. You are prohibited from posting or transmitting any material that violates any law or infringes the rights of others.</p>

            <h2>4. Intellectual Property</h2>
            <p>All content on laoloser.com, including text, graphics, logos, and software, is the property of laoloser.com or its content suppliers and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any content on this website without prior written permission.</p>

            <h2>5. Links to Third-Party Websites</h2>
            <p>Laoloser.com may contain links to third-party websites, including social media platforms and external apps. We are not responsible for the content or practices of these third-party websites. Your use of these linked websites is at your own risk.</p>

            <h2>6. Disclaimer of Warranties</h2>
            <p>Laoloser.com is provided "as is" without any warranties of any kind, either express or implied. We do not warrant that the website will be available, error-free, or free from viruses or other harmful components.</p>

            <h2>7. Limitation of Liability</h2>
            <p>In no event shall laoloser.com or its owners be liable for any damages, including but not limited to direct, indirect, incidental, or consequential damages, arising out of or in connection with the use or inability to use this website.</p>

            <h2>8. Governing Law</h2>
            <p>These Terms of Service shall be governed by and construed in accordance with the laws of the state or country in which laoloser.com is operated.</p>

            <h2>9. Contact Information</h2>
            <p>If you have any questions about these Terms of Service, please contact us at <a href="mailto:SlauxMedia@gmail.com">SlauxMedia@gmail.com</a>.</p>

            <hr />

            <h1>Privacy Policy</h1>
            <p>Last updated: 8.17.2024</p>

            <h2>1. Introduction</h2>
            <p>Laoloser.com is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit our website.</p>

            <h2>2. Information We Collect</h2>
            <ul>
                <li><strong>Personal Information:</strong> We may collect personal information such as your name and email address when you contact us, subscribe to our newsletter, or use our services.</li>
                <li><strong>Usage Data:</strong> We may collect non-personal information about your visit, such as your IP address, browser type, pages viewed, and the date and time of your visit.</li>
            </ul>

            <h2>3. Use of Information</h2>
            <ul>
                <li><strong>Personal Information:</strong> We use your personal information to respond to your inquiries, provide you with requested services, and send you updates or promotional materials if you have opted in.</li>
                <li><strong>Usage Data:</strong> We use usage data to analyze trends, administer the site, and improve our services.</li>
            </ul>

            <h2>4. Cookies</h2>
            <p>Laoloser.com may use cookies to enhance your experience on our website. Cookies are small files that are stored on your device by your browser. You can choose to disable cookies through your browser settings, but this may affect the functionality of the website.</p>

            <h2>5. Sharing of Information</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to third parties. We may share information with trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.</p>

            <h2>6. Data Security</h2>
            <p>We take reasonable precautions to protect your personal information. However, we cannot guarantee the absolute security of any information you transmit to us or that we store on our systems.</p>

            <h2>7. Third-Party Links</h2>
            <p>Laoloser.com may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of these third-party sites.</p>

            <h2>8. Children's Privacy</h2>
            <p>Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.</p>

            <h2>9. Changes to this Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page. Your continued use of the website after any changes constitutes your acceptance of the new policy.</p>

            <h2>10. Contact Information</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:SlauxMedia@gmail.com">SlauxMedia@gmail.com</a>.</p>
        </div>
    );
};

export default ToS;
