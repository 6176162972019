import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./CSVote2.css";
import ReturnToHomeButton from './ReturnToHome';

const CSVote2 = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [voteCounts, setVoteCounts] = useState([]);

    // Fetch vote counts from the backend
    const fetchVoteCounts = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/csvote2`);
            if (response.ok) {
                const data = await response.json();
                setVoteCounts(data.sort((a, b) => b.vote_count - a.vote_count)); // Sort by vote count
            } else {
                throw new Error('Failed to fetch vote counts');
            }
        } catch (error) {
            console.error('Error fetching vote counts:', error);
            toast.error('Error fetching vote counts');
        }
    };

    // Add vote
    const addVote = async (option) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/csvote2`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ vote: option })
            });
            if (response.ok) {
                fetchVoteCounts(); // Refresh the vote counts
            } else {
                throw new Error('Failed to submit vote');
            }
        } catch (error) {
            console.error('Error submitting vote:', error);
            toast.error('Error submitting vote');
        }
    };

    // Remove vote
    const removeVote = async (option) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/csvote2`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ vote: option })
            });
            if (response.ok) {
                fetchVoteCounts(); // Refresh the vote counts
            } else {
                throw new Error('Failed to remove vote');
            }
        } catch (error) {
            console.error('Error removing vote:', error);
            toast.error('Error removing vote');
        }
    };

    // Handle option selection
    const handleSelectOption = async (option) => {
        if (selectedOption === option) {
            // If the selected option is already chosen, do nothing
            return;
        }

        if (selectedOption) {
            // If there was a previously selected option, remove its vote
            await removeVote(selectedOption);
        }

        // Select the new option and add its vote
        setSelectedOption(option);
        await addVote(option);
        localStorage.setItem('selectedOption', option); // Save the selected option in localStorage
    };

    // On component mount
    useEffect(() => {
        const storedOption = localStorage.getItem('selectedOption');

        if (storedOption) {
            // Remove the previous vote if storedOption exists
            removeVote(storedOption).then(() => {
                localStorage.removeItem('selectedOption'); // Clear the storage after removing the vote
                setSelectedOption(null); // Ensure no option is selected on reload
            });
        }

        fetchVoteCounts(); // Fetch the latest vote counts

        // Set up the interval to refresh vote counts
        const interval = setInterval(fetchVoteCounts, 5000); // Refresh vote counts every 5 seconds

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []);

    const options = [
        { row: 1, items: ['Miku', 'Rin', 'Len'] },
        { row: 2, items: ['Luka', 'Meiko', 'Kaito'] },
        { row: 3, items: ['Ichika', 'Saki', 'Honami', 'Shiho'] },
        { row: 4, items: ['Minori', 'Haruka', 'Airi', 'Shizuku'] },
        { row: 5, items: ['Kohane', 'An', 'Akito', 'Toya'] },
        { row: 6, items: ['Tsukasa', 'Emu', 'Nene', 'Rui'] },
        { row: 7, items: ['Kanade', 'Mafuyu', 'Ena', 'Mizuki'] }
    ];

    return (
        <div>
            <div>
    {/* Other component content */}
    <ReturnToHomeButton />
</div>

            <h1>Challenge Show Vote</h1>
            <p>Vote for who's challenge show you want me to play</p>
            <div className="grid-container">
                {options.map((group, rowIndex) => (
                    <div key={rowIndex} className="option-row">
                        {group.items.map(option => {
                            const count = voteCounts.find(vote => vote.vote === option)?.vote_count || 0;
                            const displayName = count > 0 ? `${option} (${count})` : option;
                            return (
                                <button
                                    key={option}
                                    type="button"
                                    className={`option ${selectedOption === option ? 'selected' : ''}`}
                                    onClick={() => handleSelectOption(option)}
                                >
                                    <img src={`/assets/${option.toLowerCase()}.png`} alt={option} className="vote-image" />
                                    <span>{displayName}</span>
                                </button>
                            );
                        })}
                    </div>
                ))}
            </div>
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
        </div>
    );
};

export default CSVote2;
