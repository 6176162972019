import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LiveWidget.css';

const LiveWidget = () => {
    const [queue, setQueue] = useState([]);
    const [topVotes, setTopVotes] = useState([]);
    const [songrequestsEnabled, setSongrequestsEnabled] = useState(true);

    useEffect(() => {
        // Fetch the current song request queue
        const fetchQueue = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sradmin`);
                setQueue(response.data);
            } catch (error) {
                console.error('Error fetching queue:', error);
            }
        };

        // Fetch the top 3 votes from csvote2
        const fetchTopVotes = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/csvote2`);
                const sortedVotes = response.data.sort((a, b) => b.vote_count - a.vote_count);
                setTopVotes(sortedVotes.slice(0, 3));
            } catch (error) {
                console.error('Error fetching top votes:', error);
            }
        };

        // Fetch the request status
        const fetchRequestStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/settings`);
                const settings = response.data.reduce((acc, setting) => {
                    acc[setting.key] = setting.value;
                    return acc;
                }, {});
        
                // Convert the value to a boolean if necessary
                const isEnabled = settings.songrequests_enabled;
                const parsedEnabled = (isEnabled === true || isEnabled === 'true');
                setSongrequestsEnabled(parsedEnabled);
            } catch (error) {
                console.error('Error fetching request status:', error);
            }
        };
        
        fetchQueue();
        fetchTopVotes();
        fetchRequestStatus();

        const interval = setInterval(() => {
            fetchQueue();
            fetchTopVotes();
            fetchRequestStatus();
        }, 5000); // Refresh every 5 seconds

        return () => clearInterval(interval);
    }, []);

    // Limit the queue to the next 2 songs (current + next)
    const currentSong = queue[0];
    const nextSong = queue[1];

    return (
        <div className="live-widget">
            <div className="queue-section">
                <h2>
                    <span style={{ color: songrequestsEnabled ? 'green' : 'red' }}>
                        {songrequestsEnabled ? 'Requests Open' : 'Requests Closed'}
                    </span>
                    {' '}
                    Queue ({queue.length} songs left)
                </h2>
                <ul>
                    {currentSong && (
                        <li>
                            <strong style={{ color: 'aqua' }}>CURRENT SONG:</strong> 
                            <span style={{ color: 'white' }}> {currentSong.title} - Requested by {currentSong.user_name}</span>
                            {currentSong.special_instructions && (
                                <div style={{ color: 'red' }}>
                                    Special Instructions: {currentSong.special_instructions}
                                </div>
                            )}
                        </li>
                    )}
                    {nextSong && (
                        <li>
                            {/* <br /> */}
                            <strong style={{ color: 'teal' }}>Next song:</strong>
                            <span style={{ color: 'white' }}> {nextSong.title} - Requested by {nextSong.user_name}</span>
                            {/* Special instructions will not be shown for the next song */}
                        </li>
                    )}
                </ul>
            </div>

            {topVotes.length > 0 && (
                <div className="votes-section">
                    <h2>Top 3 Votes</h2>
                    <div className="top-votes">
                        {topVotes.map((vote, index) => (
                            <div key={index} className="top-vote-item">
                                <img src={`/assets/${vote.vote.toLowerCase()}.png`} alt={vote.vote} className="vote-image" />
                                <span className="vote-label">{vote.vote}</span>
                                <span className="vote-count">{vote.vote_count} votes</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LiveWidget;
