import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Gacha.css';
import ReturnToHomeButton from './ReturnToHome';

const Gacha = ({ isLoggedIn }) => {
    const [rollsAvailable, setRollsAvailable] = useState(0);
    const [gachaPulls, setGachaPulls] = useState([]);
    const [redeemCode, setRedeemCode] = useState('');
    const [csvoteEnabled, setCsvoteEnabled] = useState(true);

    // Define the same order used in CSVote2 for sorting and matching images
    const options = [
        { row: 1, items: ['Miku', 'Rin', 'Len'] },
        { row: 2, items: ['Luka', 'Meiko', 'Kaito'] },
        { row: 3, items: ['Ichika', 'Saki', 'Honami', 'Shiho'] },
        { row: 4, items: ['Minori', 'Haruka', 'Airi', 'Shizuku'] },
        { row: 5, items: ['Kohane', 'An', 'Akito', 'Toya'] },
        { row: 6, items: ['Tsukasa', 'Emu', 'Nene', 'Rui'] },
        { row: 7, items: ['Kanade', 'Mafuyu', 'Ena', 'Mizuki'] }
    ];

    useEffect(() => {
        if (isLoggedIn) {
            fetchGachaData();
            fetchCsvoteStatus();
            const intervalId = setInterval(fetchCsvoteStatus, 5000);
            return () => clearInterval(intervalId);
        }
    }, [isLoggedIn]);

    const fetchCsvoteStatus = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/settings`);
            const csvoteSetting = response.data.find(setting => setting.key === 'csvote_enabled');
            setCsvoteEnabled(csvoteSetting.value === 'true' || csvoteSetting.value === true);
        } catch (error) {
            console.error('Error fetching csvote status:', error);
        }
    };

    const fetchGachaData = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            toast.error('You are not logged in. Please log in to continue.');
            return;
        }

        try {
            const headers = { Authorization: `Bearer ${token}` };
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/gacha/pulls`, { headers });

            setRollsAvailable(response.data.rolls_available);

            // Sort the gacha pulls based on the order in the `options` array
            const allCharacters = options.flatMap(group => group.items);
            const sortedPulls = response.data.pulls.sort((a, b) => {
                return allCharacters.indexOf(a.character) - allCharacters.indexOf(b.character);
            });
            setGachaPulls(sortedPulls);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error('Session expired or unauthorized. Please log in again.');
                localStorage.removeItem('token');
            } else {
                toast.error('Failed to fetch gacha data');
            }
            console.error('Error fetching gacha data:', error);
        }
    };

    const handleGachaPull = async () => {
        if (rollsAvailable <= 0) {
            toast.error('No rolls available');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error('You are not logged in. Please log in to continue.');
                return;
            }

            const headers = { Authorization: `Bearer ${token}` };
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/gacha/pull`, {}, { headers });
            setRollsAvailable(rollsAvailable - 1);
            toast.success(`You pulled ${response.data.character}`);

            // Fetch updated pulls after pull
            fetchGachaData();
        } catch (error) {
            console.error('Error performing gacha pull:', error);
            toast.error('Failed to perform gacha pull');
        }
    };

    const handleSubmitVote = async (character, cardId) => {
        if (!csvoteEnabled) {
            toast.error('Challenge Show Voting is currently closed. You cannot submit votes.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/gacha/vote`, { character, cardId }, { headers });

            toast.success(`Successfully submitted 1 vote for ${character}`);

            // Update the card count after the vote
            setGachaPulls((prevPulls) => prevPulls.filter((pull) => pull.card_id !== cardId));
        } catch (error) {
            console.error('Error submitting vote:', error);
            toast.error('Failed to submit vote');
        }
    };

    // Handle redeeming a code for additional pulls
    const handleRedeemCode = async () => {
        if (!redeemCode) {
            toast.error('Please enter a valid redemption code');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/gacha/redeem`, { code: redeemCode }, { headers });
            setRollsAvailable((prevRolls) => prevRolls + response.data.pulls);
            toast.success(`Successfully redeemed code: ${redeemCode}`);

            setRedeemCode(''); // Clear the input after success
            fetchGachaData(); // Fetch updated pulls
        } catch (error) {
            console.error('Error redeeming code:', error);
            toast.error('Failed to redeem code');
        }
    };

    return (
        <div className="gacha-container">
            <div>
    {/* Other component content */}
    <ReturnToHomeButton />
</div>

            <h1>Gacha Pulls</h1>
            <p>Available Rolls: {rollsAvailable}</p>
            <button onClick={handleGachaPull} disabled={rollsAvailable <= 0} className="pull-button">
                Pull Gacha
            </button>

            <h2>Redeem Code</h2>
            <input
                type="text"
                value={redeemCode}
                onChange={(e) => setRedeemCode(e.target.value)}
                placeholder="Enter code"
                className="redeem-input"
            />
            <button onClick={handleRedeemCode} className="redeem-button">Redeem</button>

            <h2>My Gacha Pulls</h2>
            {!csvoteEnabled && <p className="voting-closed">Challenge Show Voting is currently closed.</p>}
            <div className="gacha-pulls-grid">
                {gachaPulls.length === 0 ? (
                    <p>No pulls yet</p>
                ) : (
                    gachaPulls.map((pull) => (
                        <div key={pull.card_id} className="gacha-item">
                            <img
                                src={`/assets/${pull.character.toLowerCase()}.png`}
                                alt={pull.character}
                                className="gacha-image"
                            />
                            <span>{pull.character}</span>
                            <button
                                onClick={() => handleSubmitVote(pull.character, pull.card_id)}
                                className="vote-button"
                                disabled={!csvoteEnabled}
                            >
                                Submit Vote
                            </button>
                        </div>
                    ))
                )}
            </div>
            <ToastContainer position="top-left" autoClose={2000} theme="dark" hideProgressBar={false} />
        </div>
    );
};

export default Gacha;
