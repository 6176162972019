import React from 'react';
import { Link } from 'react-router-dom';

const ReturnToHomeButton = () => {
    return (
        <div className="return-home-container">
            <Link to="/" className="return-home-button">
                Return to Home
            </Link>
        </div>
    );
};

export default ReturnToHomeButton;
