import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import ReturnToHomeButton from './ReturnToHome';

const Register = () => {
    const [form, setForm] = useState({ username: '', email: '', password: '' });
    const navigate = useNavigate(); // Initialize useNavigate

    const handleInputChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleRegister = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/register`, form);
            toast.success('Registration successful');
            setTimeout(() => {
                navigate('/'); // Redirect to the home page
            }, 2000); // Optional delay to allow the toast message to display
        } catch (error) {
            toast.error('Registration failed');
        }
    };

    return (
        <div className="register-container">
            <div>
    {/* Other component content */}
    <ReturnToHomeButton />
</div>

            <h2>Register</h2>
            <input
                type="text"
                name="username"
                placeholder="Username"
                value={form.username}
                onChange={handleInputChange}
            />
            <input
                type="email"
                name="email"
                placeholder="Email"
                value={form.email}
                onChange={handleInputChange}
            />
            <input
                type="password"
                name="password"
                placeholder="Password"
                value={form.password}
                onChange={handleInputChange}
            />
            <button onClick={handleRegister}>Register</button>
            <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
        </div>
    );
};

export default Register;
